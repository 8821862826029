import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import {
  GetJourneyPlanForViewDto,
  JourneyPlanServiceProxy,
} from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { JourneyPlanFilter } from "@/models";
import { CancelToken } from "axios";

interface UsableModel {
  journeyPlans: Ref<Array<GetJourneyPlanForViewDto>>;
  getJourneyPlans: (filters: JourneyPlanFilter) => void;
  getForCustomer: (
    customerId: string | undefined,
    cancelToken?: CancelToken | undefined
  ) => void;
}

const UsableModel = (): UsableModel => {
  const store = useStore();
  const client = new JourneyPlanServiceProxy(APP_BASE_URL, axiosClient);

  const journeyPlans: Ref<Array<GetJourneyPlanForViewDto>> = ref([]);

  const getJourneyPlans = async (filters: JourneyPlanFilter) => {
    const {
      weekdayFilter,
      uRNOFilter,
      agentId,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    } = filters;

    await client
      .getAll(
        weekdayFilter,
        uRNOFilter,
        agentId,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        const { items } = data;

        journeyPlans.value = items as unknown as GetJourneyPlanForViewDto[];
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getForCustomer = async (
    customerId: string | undefined,
    cancelToken?: CancelToken | undefined
  ) => {
    await client
      .getForCustomer(customerId, cancelToken)
      .then((data) => {
        journeyPlans.value = data as unknown as GetJourneyPlanForViewDto[];
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  return {
    journeyPlans,
    getJourneyPlans,
    getForCustomer,
  };
};

export default UsableModel;

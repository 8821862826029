
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import AJLoader from "@/components/AjaxLoader.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { JourneyPlanFilter } from "@/models";
import useJourneyPlan from "@/composables/useJourneyPlan";
import { GetJourneyPlanForViewDto } from "@/shared/service-proxies/service-proxies";

export default defineComponent({
  components: {
    Datatable,
    AJLoader,
  },
  setup() {
    const loading = ref<boolean>(true);
    const { journeyPlans, getJourneyPlans } = useJourneyPlan();

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "URNO",
        key: "urno",
      },
      {
        name: "Customer Name",
        key: "customerName",
      },
      {
        name: "Phone Number",
        key: "phoneNumber",
      },
      {
        name: "Week Day",
        key: "weekDay",
        sortable: true,
      },
    ]);
    const tableData = ref<Array<GetJourneyPlanForViewDto> | undefined>([]);
    const dataFilter = ref<JourneyPlanFilter>({
      weekdayFilter: -1,
      skipCount: 0,
      uRNOFilter: undefined,
      sorting: undefined,
      maxResultCount: 10000,
    } as unknown as JourneyPlanFilter);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("JourneyPlan", ["Journey plan"]);

      await getData();

      loading.value = false;
    });

    const getData = async () => {
      loading.value = true;

      await getJourneyPlans(dataFilter.value as unknown as JourneyPlanFilter);

      tableData.value = journeyPlans.value;

      loading.value = false;
    };

    return {
      loading,
      tableData,
      tableHeader,
      dataFilter,
      moment,
      getData,
    };
  },
});
